<template>
  <div class="order-list">
    <van-tabs v-model="tabActive">
      <van-tab v-for="item of vtab" :key="item.name" :title="item.title" :name="item.name">
        <div class="lists" v-if="lists.length">
          <van-card
            v-for="item in lists"
            :key="item.apmtid"
            :price="item.amount"
            :desc="item.roomltitle"
            :title="item.hoteltitle"
            :thumb="item.hotelthumb"
            num="1"
            class="lists-item"
            lazy-load
            @click="handleToDetail(item)"
          />
        </div>
        <van-empty
          v-else
          class="empty"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂无数据"
        />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
const tabKeys = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  6: 6
}

const vtab = [
  {
    title: '全部',
    name: 0
  },
  {
    title: '待预约',
    name: 1
  },
  {
    title: '预约中',
    name: 2
  },
  {
    title: '已预约',
    name: 3
  },
  {
    title: '退款中',
    name: 7
  },
  {
    title: '已退款',
    name: 6
  }
]

import { getBookList } from "@/api"
import { SESSION_NAME } from "@/utils/login"
export default {
  name: 'order-list-page',
  data() {
    return {
      vtab: vtab,
      tabActive: tabKeys[this.$route.params.type] || 0,
      lists: []
    }
  },
  methods: {
    handleToDetail(item) {
      this.$router.push({ name: 'orderdetail', query: { id: item.apmtid } })
    },
    getList() {
      getBookList({ token: localStorage.getItem(SESSION_NAME), status: this.tabActive, page: 1, pagesize: 9999 }).then(res => {
        const { data } = res
        this.lists = data.content || []
      }).catch(() => {
        this.lists = []
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tabActive = tabKeys[this.$route.params.type]
    })
  },
  watch: {
    tabActive: {
      handler() {
        this.getList()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.order-list {
  ::v-deep {
    .van-tabs__wrap {
      position: fixed;
      left: 0;
      right: 0;
      top: 45px;
      z-index: 2;
    }
  }

  .lists {
    padding-top: 90px;
    overflow-y: scroll;

    &-item {
      background: #fff;
      width: 360px;
      margin: 5px auto;
      border-radius: 5px;
    }
  }

  .empty {
    margin-top: 85px;
  }
}
</style>
